.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body {
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif; */
  /* background-color: #fff4f7; */
  /* background-color: rgb(255, 246, 236); */
  /* background: linear-gradient(#94bbe9, #eeaeca); */
  /* background: linear-gradient(rgb(255, 246, 236), #94bbe9); */
  /* padding-bottom: 30px !important; */
}


html {
  scroll-behavior: smooth !important;
}

.navigation{
  background-color: #D5B8D7 !important;
}

.navMainText {
  color: rgb(244, 236, 236) !important;
  font-size: 200%;
}
.navText {
  color: white !important;
  font-size: 150%;
}

.navText:hover {
  text-decoration: underline;
}


#drop {
  /* background-image: url('https://i.etsystatic.com/6083130/r/il/08ce07/3773221434/il_1588xN.3773221434_p2vu.jpg'); */
  /* background-image: url('./files/star-bg.png'); */
  background-size: 80%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  /* gap: 1rem; */
}


.btn-outline {
  border-color: bisque;
  color: #e17721;
  font-size: x-large;
  /* border-color:#F4AC32 !important; */
}

.dropButton {
  background-color: bisque;
  color: #e17721;
  font-size: x-large;
  border: 5px #F4AC32 solid !important;
  /* border-color:#F4AC32 !important; */
} 
.dropButton:hover {
  background-color: #de8a46;
  color: bisque;
  font-size: x-large;
  border: 5px bisque solid !important;
}

.dropCard {
  background-color: rgb(247, 230, 209);
  color: #e17721;
  font-size: x-large;
  border: 5px #F4AC32 solid !important;
  text-align: left;
  width: 100%;
  /* border-color:#F4AC32 !important; */
}

.footer {
  background-color: #9BB897;
  color:rgb(244, 236, 236) !important;
}

.footerLink:hover {
  border: 2px rgb(244, 236, 236) solid !important;
  border-radius: 5px;
}

.aboutText {
  text-align: left;
  font-size: large;
}

.headingText {
  font-weight: bold;
  /* text-align: left; */
}

.contact-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.contactButton {
  background-color: rgb(247, 230, 209);
  color: #e17721;
  border: 2px #F4AC32 solid !important;
}

.contactButton:hover {
  background-color: #df9356;
  color: rgb(247, 231, 211);
  border: 2px bisque solid !important;
}

.aboutImage {
  max-width: 600px;
}

#resume {
  max-width: 80%;
  margin: auto;
}

#resumeFile {
align-self: center;}

.react-pdf__Page {
  min-width: inherit !important;
}

.react-pdf__Page__canvas {
  width: 100% !important;
  height: 100% !important;
  display: inline !important;
}

#resumeContainer{
  max-height: 70vh;
  overflow-x: hidden !important;
}

#igContainer{
  max-height: 75vh !important;
}

.react-pdf__Document {
  max-height: inherit;
}


#contactForm {
  min-width: 67vw;
}

#earthGLB {
  height: 75vh !important;
  width: auto !important;
}

#navImg {
  max-height: 3rem;
}

#triviaContainer {
  width: 60% !important;
  min-height: 100% !important;
  max-height: 100% !important;
}

#hobbyContainer {
  width: 38% !important;
  min-height: 100% !important;
  max-height: 100% !important;
}

#clockContainer {
  min-height: 100% !important;
  max-height: 100% !important;
}

.react-clock__face {
  border: none !important;
}

.react-clock {
  width: 50% !important;
  height: 50% !important;
}

/* styling inspired by https://www.npmjs.com/package/simple-react-footer?activeTab=code */
#footer{
    margin-top: 30px;
    height: fit-content;
    width: auto;
    justify-content: space-between;
    display: flex;
    flex-direction: row;
    padding: 20px 30px 5px 30px;
    flex-wrap: wrap;
    background-color: #9BB897;
    color:rgb(244, 236, 236) !important;
}